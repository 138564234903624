import { useNavigate } from 'react-router-dom';
import caregiverimg from '../assets/Contactleft.png';
import contactbook1 from '../assets/contactbook1.png';
import contactbook2 from '../assets/contactbook2.png';
import Header from '../Layout/Header';
import { useState } from 'react';
import { useFormik } from 'formik';
import { contact } from '../service/Mainservice';
import { Alert, Snackbar } from '@mui/material';
import Loader from '../Loader/Loader';
import Privacy from './Customer/Privacy';
import { Helmet } from 'react-helmet';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  } else if (values.name.length > 100) {
    errors.name = 'Must be 100 characters or less';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.contact) {
    errors.contact = 'Required';
  }

  if (!values.message) {
    errors.message = 'Required';
  } else if (values.message.length > 200) {
    errors.message = 'Must be 100 characters or less';
  }
  return errors;
};

const Contact = () => {
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setsuccess] = useState();
  const [terms, setterms] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const closealert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handlesubmit = async (values, { resetForm }) => {
    setload(true);
    try {
      const response = await contact(values);
      setsuccess(response.data.message);
      setOpen(true);

      resetForm();
      setload(false);
    } catch (error) {
      console.log(error);

      setload(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
      contact: '',
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      handlesubmit(values, { resetForm });
    },
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Get in Touch with Our Team|i-Companion </title>
        <link rel="canonical" href="https://i-companion.com/Contact-Us" />
        <meta
          name="description"
          content="Need help or have questions? Contact the i-Companion team for assistance and support. We're here to help you with all your caregiving platform needs."
        />
        <meta name="google-site-verification" content="sMbNX_0xhbBQ1PX2a3QTp1oXNKT-KX6xEPItn9Y9iTA" />
      </Helmet>

      <Header title="Contact Us" headerbg="contactbg" />
      <div className="Bookcaregiverdiv">
        <img
          src={caregiverimg}
          alt="Companion Care"
          className="Bookcaregiverimg"
          style={{ margin: '30px 0' }}
          loading="lazy"
        />

        <form
          className="Bookcaregiverdiv_left"
          style={{ marginTop: '35px' }}
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <h2>Contact Us</h2>
          <div className="Bookcaregiver_inputfield_divs">
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="name">Name</label>
              <input
                // onChange={handlechange}
                onChange={formik.handleChange}
                value={formik.values.name}
                className="Bookcaregiver_input"
                name="name"
                type="text"
                placeholder="Name"
              />
              {formik.errors.name ? (
                <div className="validation_error" style={{ margin: '2px 0' }}>
                  {formik.errors.name}
                </div>
              ) : null}
            </div>
            <div className="Bookcaregiver_inputfield">
              <label htmlFor="name">Email</label>
              <input
                onChange={formik.handleChange}
                value={formik.values.email}
                name="email"
                type="text"
                placeholder="Email"
                className="Bookcaregiver_input"
              />
              {formik.errors.email ? (
                <div className="validation_error" style={{ margin: '2px 0' }}>
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
          </div>
          <div className="Bookcaregiver_inputfield">
            <label htmlFor="contact">Contact Number</label>
            <input
              onChange={formik.handleChange}
              value={formik.values.contact}
              name="contact"
              type="number"
              placeholder="Contact number"
              className="Bookcaregiver_input"
            />
            {formik.errors.contact ? (
              <div className="validation_error" style={{ margin: '2px 0' }}>
                {formik.errors.contact}
              </div>
            ) : null}
          </div>

          <div className="Bookcaregiver_inputfield">
            <label htmlFor="name">Message</label>
            <textarea
              onChange={formik.handleChange}
              value={formik.values.message}
              cols={15}
              name="message"
              placeholder="Write Here"
              className="Bookcaregiver_input"
            />
            {formik.errors.message ? (
              <div className="validation_error" style={{ margin: '2px 0' }}>
                {formik.errors.message}
              </div>
            ) : null}
          </div>
          <div className="Bookcaregiver_check" onClick={() => setShowTerms(true)}>
            <input
              type="checkbox"
              name="terms"
              id="terms"
              onClick={(e) => {
                setterms(e.target.checked);
              }}
            />
            <label htmlFor="consent" style={{ fontSize: '13px', cursor: 'pointer' }}>
              By clicking this check box you agree to receive text messages from i-Companion Care LLC{' '}
            </label>
          </div>

          <button className="Bookcaregiver_button" type="submit">
            Submit
          </button>
        </form>
      </div>
      <div className="servicediv">
        <div className="servicecontactinfo">
          <h2 className="servicediv_title">INTERESTED IN CARE OR WANT TO PROVIDE CARE</h2>
          <p className="servicediv_detail">
            If you’re ready to explore our services, don’t hesitate to get in touch. We’re here to answer any questions
            and provide you with the information you need to make the best decision for your family. Let i-Companion
            provide the companionship and care your loved ones deserve.
          </p>
          <div className="services_buttonlist">
            <a href="tel:(408) 579-9929" className="services_button">
              Call Us (408) 579-9929
            </a>{' '}
          </div>
        </div>
        {/* <img src={serviceleft} alt="" /> */}
      </div>

      {/* <div className="contactdiv">
        <h2 className="contactdiv_title">
          Have Questions And Want <br /> To Meet?
        </h2>
        <div className="services_buttonlist">
          <img src={contactbook1} alt="" className="contactbookimg" />
          <div className="contactbookdetails">
            <h3>I want Companionship Care</h3>
            <p>15 mins Free</p>
            <button className="Bookcaregiver_button">Book Now</button>
          </div>
          <img src={contactbook2} alt="" className="contactbookimg" />
          <div className="contactbookdetails">
            <h3>I want to become a Care Provider</h3>
            <p>15 mins Free</p>
            <button className="Bookcaregiver_button">Book Now</button>
          </div>
        </div>
      </div> */}
      {load && <Loader />}
      <Privacy setShowTerms={setShowTerms} showTerms={showTerms} />
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={closealert}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Contact;

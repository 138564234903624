import { Link, useNavigate } from 'react-router-dom';

import './Nav.css';
import mobilelogo from '../assets/Mobilelogoblack.png';

import logo from '../assets/brownlogo.png';
import profileimg from '../assets/profileimg.png';
import { useEffect, useRef, useState } from 'react';
// import Choosetyp from "../componant/Auth/Choosetype";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationModal from './Notification';
import Menu from './Menu';
const Nav = () => {
  const [islogin, setislogin] = useState(false);
  const [showmobilemenu, setshowmobilemenu] = useState(false);
  const [menu, setmenu] = useState(false);
  const [shownotification, setshownotification] = useState(false);
  const [reg, setreg] = useState(false);
  const [type, settype] = useState(false);
  const navigate = useNavigate();
  const profile = JSON.parse(localStorage.getItem('data'));
  const menuref = useRef();

  useEffect(() => {
    const userToken = localStorage.getItem('ntk');
    if (!userToken || userToken === null) {
      setislogin(false);
    } else {
      setislogin(true);
    }

    const handler = (event) => {
      if (menuref.current && !menuref.current.contains(event.target)) {
        setshownotification(false);
        setmenu(false);
        // setselectedprofile(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  return (
    <>
      <nav className="Navbar">
        <div className="Nav_list">
          <div>
            <img src={logo} alt="logo" className="Nav_logo" loading="lazy" />
            <img src={mobilelogo} alt="mobilelogo" className="Header_mobile_logo" loading="lazy" />
          </div>
          <ul className="Nav_menulist">
            <li>
              <Link className="Nav_menu" to="/">
                Home{' '}
              </Link>
            </li>
            <li>
              <Link className="Nav_menu" to="/About-Us">
                About Us{' '}
              </Link>
            </li>
            <li>
              <Link className="Nav_menu" to="/Companion-Care-Service">
                Services{' '}
              </Link>
            </li>
            <li>
              <Link className="Nav_menu" to="/Contact-Us">
                Contact Us{' '}
              </Link>
            </li>
            <li>
              <Link className="Nav_menu" to="/HowitWorks">
                How it Work{' '}
              </Link>
            </li>
          </ul>
          <div className="header_profileinfodiv">
            {islogin ? (
              <>
                <div className="Header_login_details" onClick={() => setmenu(true)}>
                  <img alt="img" src={process.env.REACT_APP_IMAGE_URL + profile?.profile_pic} loading="lazy" />
                  <div className="Nav_menu" style={{ cursor: 'pointer' }}>
                    {profile?.full_name}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 24 24"
                      fill="none"
                      className="arrowsvg"
                    >
                      <g clipPath="url(#clip0_108_1892)">
                        <path
                          d="M12.0002 12.172L14.8282 9.34299L16.2432 10.757L12.0002 15L7.75716 10.757L9.17216 9.34299L12.0002 12.172Z"
                          fill="var(--black)"
                          className="arrowsvg"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_108_1892">
                          <rect width="19" height="19" fill="white" transform="matrix(0 1 -1 0 24 0)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                {/* <Badge
                  badgeContent={4}
                  color="default"
                  // sx={{ color: "var(--pink)", backgroundColor: "var(--black)" }}
                  onClick={() => setshownotification(true)}
                >
                  <NotificationsNoneIcon color="action" />
                </Badge> */}
                {menu && <Menu showmenu={setmenu} menuref={menuref} />}
              </>
            ) : (
              <button className="Nav_menu_button" onClick={() => navigate('/Login')}>
                <span>Login</span>
              </button>
            )}
            <div className="header_menuicon">
              <MenuIcon
                sx={{
                  color: 'var(--black)',
                  fontSize: 19,
                  cursor: 'pointer',
                }}
                onClick={() => setshowmobilemenu(true)}
              />
            </div>
          </div>
        </div>
        <div className="Header_mobilelist">
          <div className={showmobilemenu ? 'modal-overlay' : ''}>
            <ul className={showmobilemenu ? 'Header_mobilemenulist' : 'Header_menulist'}>
              <li>
                <CloseIcon className="menucloseicon" onClick={() => setshowmobilemenu(false)} />
              </li>

              <li>
                <Link className="Header_menu" to="/" onClick={() => setshowmobilemenu(false)}>
                  Home{' '}
                </Link>
              </li>
              <li>
                <Link className="Header_menu" to="/About-Us">
                  About Us{' '}
                </Link>
              </li>
              <li>
                <Link className="Header_menu" to="/Companion-Care-Service">
                  Services{' '}
                </Link>
              </li>
              <li>
                <Link className="Header_menu" to="/Contact-Us">
                  Contact Us{' '}
                </Link>
              </li>
              <li>
                <Link className="Header_menu" to="/HowitWorks">
                  How it Work
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {shownotification && <NotificationModal shownf={shownotification} menuref={menuref} />}
    </>
  );
};
export default Nav;

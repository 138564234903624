import { Link, useLocation, useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import './Header.css';
import logo from '../assets/logo.png';
import mobilelogo from '../assets/mobile_logo.png';
import profileimg from '../assets/profileimg.png';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Menu from './Menu';

const Header = ({ headerbg, title }) => {
  const navigate = useNavigate();
  const [islogin, setislogin] = useState(false);
  const [showmobilemenu, setshowmobilemenu] = useState(false);
  const [menu, setmenu] = useState(false);
  const profile = JSON.parse(localStorage.getItem('data'));
  const menuref = useRef();
  const location = useLocation();

  useEffect(() => {
    const userToken = localStorage.getItem('ntk');
    if (!userToken || userToken === null) {
      setislogin(false);
    } else {
      setislogin(true);
    }
    const handler = (event) => {
      if (menuref.current && !menuref.current.contains(event.target)) {
        // setshownotification(false);
        setmenu(false);
        // setselectedprofile(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);
  return (
    <>
      <header className={headerbg}>
        <nav className="Headerbar">
          <ul className="Header_list">
            <div>
              <img src={logo} alt="logo" className="Header_logo" loading="lazy" />
              <img src={mobilelogo} alt="mobilelogo" className="Header_mobile_logo" loading="lazy" />
            </div>
            <div className="Header_menulist">
              <li>
                <Link className="Header_menu" to="/">
                  Home{' '}
                </Link>
              </li>
              <li>
                <Link className="Header_menu" to="/About-Us">
                  About Us{' '}
                </Link>
              </li>
              <li>
                <Link className="Header_menu" to="/Companion-Care-Service">
                  Services{' '}
                </Link>
              </li>
              <li>
                <Link className="Header_menu" to="/Contact-Us">
                  Contact Us{' '}
                </Link>
              </li>
              <li>
                <Link className="Header_menu" to="/HowitWorks">
                  How it Work{' '}
                </Link>
              </li>
            </div>
            <div className="header_profileinfodiv">
              {location.pathname === '/BecameaCompanion' ||
              location.pathname === '/BecameaCompanion2' ||
              location.pathname === '/BecameaCompanion3' ||
              location.pathname === '/Daily_Availability' ||
              location.pathname === '/ClientDetails' ||
              location.pathname === '/Billing&carddetail' ? (
                ''
              ) : (
                <>
                  {islogin ? (
                    <>
                      <div className="Header_login_details" onClick={() => setmenu(true)}>
                        <img alt="img" src={process.env.REACT_APP_IMAGE_URL + profile?.profile_pic} loading="lazy" />
                        <div className="Header_menu" style={{ cursor: 'pointer' }}>
                          {profile?.full_name}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_108_1892)">
                              <path
                                d="M12.0002 12.172L14.8282 9.34299L16.2432 10.757L12.0002 15L7.75716 10.757L9.17216 9.34299L12.0002 12.172Z"
                                fill="var(--white)"
                                className="arrowsvg"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_108_1892">
                                <rect width="19" height="19" fill="white" transform="matrix(0 1 -1 0 24 0)" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      {/* <Badge
                  badgeContent={4}
                  color="default"
                  // sx={{ color: "var(--pink)", backgroundColor: "var(--black)" }}
                  onClick={() => setshownotification(true)}
                >
                  <NotificationsNoneIcon color="action" />
                </Badge> */}
                      {menu && <Menu showmenu={setmenu} menuref={menuref} />}
                    </>
                  ) : (
                    <button className="Header_menu_button" onClick={() => navigate('/Login')}>
                      <span>Login</span>
                    </button>
                  )}
                </>
              )}
              <div className="header_menuicon">
                <MenuIcon
                  sx={{
                    color: 'var(--white)',
                    fontSize: 19,
                    cursor: 'pointer',
                  }}
                  onClick={() => setshowmobilemenu(true)}
                />
              </div>
            </div>
          </ul>
          <div className="Header_mobilelist">
            <div className={showmobilemenu ? 'modal-overlay' : ''}>
              <ul className={showmobilemenu ? 'Header_mobilemenulist' : 'Header_menulist'}>
                <li>
                  <CloseIcon className="menucloseicon" onClick={() => setshowmobilemenu(false)} />
                </li>

                <li>
                  <Link className="Header_menu" to="/">
                    Home{' '}
                  </Link>
                </li>
                <li>
                  <Link className="Header_menu" to="/About-Us">
                    About Us{' '}
                  </Link>
                </li>
                <li>
                  <Link className="Header_menu" to="/Companion-Care-Service">
                    Services{' '}
                  </Link>
                </li>
                <li>
                  <Link className="Header_menu" to="/Contact-Us">
                    Contact Us{' '}
                  </Link>
                </li>
                <li>
                  <Link className="Header_menu" to="/HowitWorks">
                    How it Work{' '}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="header_titlediv">
          {/* <KeyboardBackspaceIcon
            className="header_backbtn"
            sx={{ color: "var(--white)" }}
          /> */}
          <h1 className="Header_title">{title}</h1>
        </div>
      </header>
    </>
  );
};
export default Header;

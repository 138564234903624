import { useNavigate } from 'react-router-dom';
import serviceleft from '../assets/servicecontact.jpg';
import Header from '../Layout/Header';
import { Helmet } from 'react-helmet';

const Service = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Companion Care at Home | Elderly Companion Services in California</title>
        <link rel="canonical" href="https://i-companion.com/Companion-Care-Service" />
        <meta
          name="description"
          content="Looking for senior companion care in California? i-Companion provides reliable companion care services for seniors in the comfort of their homes."
        />
        <meta name="google-site-verification" content="sMbNX_0xhbBQ1PX2a3QTp1oXNKT-KX6xEPItn9Y9iTA" />
      </Helmet>

      <Header title="Our Services" headerbg="servicebg" />
      <p className="servicediv_detail">
        In i-Companion, we're experts in offering seniors who want to maintain their independence while getting the
        assistance and care they want top-notch companion home care services. Our selection of services is intended to
        improve the quality of life for senior citizens by assisting them in maintaining an active, involved, and secure
        lifestyle in the comfort of their own homes. Whether you're looking for occasional companionship or more
        consistent care, our dedicated team is here to provide trusted and compassionate assistance. Our services focus
        on the well-being of seniors, ensuring they receive emotional support, help with daily tasks, and a sense of
        security.
      </p>
      <h2 className="servicedes">Companion Home Care</h2>
      <p className="servicediv_detail">
        Our companion home care services are made especially to cater to the demands of elderly people who need help
        with daily tasks but yet wish to be independent. Our carers create a cosy and secure atmosphere at home by
        providing non-medical help.
      </p>
      <p className="servicediv_detail">Among the things we help with are:</p>
      <ul>
        <li className="service_data">
          {' '}
          <strong>Light Housekeeping: </strong>We assist in keeping your house nice and orderly, from washing to
          cleaning up.
        </li>
        <li className="service_data">
          <strong>Meal Preparation: </strong> Our carers make sure elders have a balanced diet by preparing wholesome
          meals.
        </li>
        <li className="service_data">
          <strong>Errands and Shopping:</strong> We help elders by taking care of their medications, food shopping, &
          other errands, allowing them to concentrate on their well-being.
        </li>
        <li className="service_data">
          <strong>Transportation:</strong> In order to keep elders engaged in the community, we offer transportation to
          doctor's appointments
        </li>
      </ul>
      <p className="servicediv_detail">
        Our Companion <strong>Home Care services </strong>are available on a flexible schedule, from a few hours a week
        to full-time support, based on the unique needs of each individual
      </p>
      <h2 className="servicedes">Senior Companionship Services</h2>
      <p className="servicediv_detail">
        Loneliness and isolation are common challenges for seniors, and our
        <strong>Senior Companionship Services</strong> aim to combat that by providing regular, meaningful interactions.
        Our caregivers are not just there to assist with physical tasks but also to offer emotional support and
        friendship.
      </p>
      <p className="servicediv_detail">Our Senior Companionship Services include:</p>
      <ul>
        <li className="service_data">
          <strong>Engaging Conversations</strong>: We give seniors a companion with whom to discuss any topic, including
          ordinary life, current affairs, or their favourite memories.
        </li>
        <li className="service_data">
          {' '}
          <strong>Interests and pastimes:</strong> Our carers urge elderly citizens to maintain an active lifestyle by
          engaging in interests and pastimes like card games, reading, or strolling.{' '}
        </li>
        <li className="service_data">
          <strong>Social Interaction:</strong> Keeping up social ties is essential to mental well-being. In order to
          lessen feelings of loneliness, we assist elders in going to social gatherings or just spending time with a
          partner.
        </li>
      </ul>
      <p className="servicediv_detail">
        By offering personalized Senior Companionship Services, we ensure that seniors feel connected, heard, and
        valued, improving their overall quality of life.
      </p>
      <h2 className="servicedes">Elderly Companion Services</h2>
      <p className="servicediv_detail">
        As part of our comprehensive care, our Elderly Companion Services are designed to provide ongoing support to
        elderly individuals who need a companion to assist with their day-to-day needs. This service is ideal for
        seniors who may live alone or need additional help to remain safe and comfortable at home.{' '}
      </p>
      <p className="servicediv_detail">Our Elderly Companion Services focus on: </p>
      <ul>
        {' '}
        <li className="service_data">
          <strong>Safety and security:</strong> Our carers make sure seniors live in a secure setting by helping them
          move about the house and averting mishaps or falls.
        </li>
        <li className="service_data">
          <strong>Daily Assistance:</strong> We provide assistance with household chores, medicine administration, and
          monitoring elders' adherence to daily schedules.
        </li>
        <li className="service_data">
          <strong>Emotional assistance:</strong> Providing emotional solace is equally as crucial as providing physical
          assistance. Our carers are skilled in providing considerate care that attends to patients' practical as well
          as emotional requirements.
        </li>
      </ul>
      <p className="servicediv_detail">
        With <strong>Elderly Companion Services</strong>, families can rest assured that their loved ones are receiving
        the highest standard of care, tailored to their unique needs.
      </p>
      <h2 className="servicedes">Companion Caregiver Services</h2>
      <p className="servicediv_detail">
        Our trained and professional Companion Caregivers provide personalized care that goes beyond just basic support.
        They are dedicated to helping seniors maintain their dignity and independence while offering the level of care
        needed for a comfortable life at home.
      </p>
      <p className="servicediv_detail">
        Our <strong>Companion Caregiver Services</strong> include:{' '}
      </p>
      <ul>
        <li className="service_data">
          <strong>Tailored Care Plans:</strong> We create customised care plans that are tailored to the unique
          requirements and preferences of every senior. We customise our services based on whether your loved one needs
          full-time care or simply a few hours of company.{' '}
        </li>
        <li className="service_data">
          <strong>Experienced Caregiver's:</strong> Every one of our carers is a kind, well-trained professional who is
          aware of the particular difficulties that elderly people encounter. We take great satisfaction in finding each
          customer the ideal carer, fostering a relationship based on mutual respect and trust.
        </li>
      </ul>
      <h2 className="servicedes">Why Choose an i-Companion?</h2>
      <p className="servicediv_detail">
        We at i-Companion know how crucial it is to locate trustworthy and caring carers for your loved ones. We
        distinguish ourselves as a reliable supplier of senior care in California by concentrating on providing
        individualised, superior companion home care, senior companionship services, and elderly companion services. We
        believe that seniors deserve to live life to the fullest, with the support they need to remain independent and
        engaged. Our caregivers are here to provide that support, ensuring that seniors and their families feel
        confident and cared for.
      </p>
      <div className="servicediv">
        <div className="servicecontactinfo">
          <h2 className="servicediv_title">INTERESTED IN CARE OR WANT TO PROVIDE CARE</h2>
          <div className="services_buttonlist">
            <a href="tel:(408) 579-9929" className="services_button">
              Call Us (408) 579-9929
            </a>{' '}
            <a href="mailto:info@i-companion.com" className="services_button">
              Email Us
            </a>{' '}
          </div>
        </div>
        <img src={serviceleft} alt="service" loading="lazy" />
      </div>
    </>
  );
};
export default Service;

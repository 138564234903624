import { useNavigate } from 'react-router-dom';
import caregiverimg from '../assets/aboutleft.png';
import Header from '../Layout/Header';
import { Helmet } from 'react-helmet';

const About = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Your Trusted Digital Companion | i-Companion </title>
        <link rel="canonical" href="https://i-companion.com/About-Us" />
        <meta
          name="description"
          content="Learn more about i-Companion, the innovative platform that connects caregivers and those in need.Discover our vision and how we are transforming the caregiving industry."
        />
        <meta name="google-site-verification" content="sMbNX_0xhbBQ1PX2a3QTp1oXNKT-KX6xEPItn9Y9iTA" />
      </Helmet>

      <Header title="About Us" headerbg="aboutbg" />
      <div className="Bookcaregiverdiv">
        <div className="Bookcaregiverdiv_left" style={{ marginTop: '35px' }}>
          <h2>About i-Companion care</h2>
          <p className="about_data">
            Our goal at i-Companion is straightforward yet profound to offer seniors throughout California caring and
            committed companion care services. We are aware that preserving one's freedom and standard of living grows
            more difficult as people age. At this point, i-Companion can help by providing individualized care plans
            made to address seniors' practical, social, and emotional needs while they remain in the comfort of their
            own homes.
          </p>
          <p className="about_data">
            Our staff of skilled caregivers, who have years of experience in the home care sector, is committed to
            making sure that each client receives outstanding care that is customized to their particular situation. Our
            services, which range from companionship to light housekeeping, are meant to give seniors the help they need
            to keep leading happy, independent lives.
          </p>{' '}
        </div>

        <img
          src={caregiverimg}
          alt="Companion Care at Home"
          className="Bookcaregiverimg"
          style={{ margin: '30px 0' }}
          loading="lazy"
        />
      </div>

      <h2 className="servicedes">Our Story</h2>

      <p className="servicediv_detail">
        The founding of i-Companion was motivated by the desire to close the gap that exists between seniors and the
        company they require to flourish. The author was inspired by personal experience of the beneficial effects that
        a kind and considerate companion can have on an elderly person's quality of life. The loss of freedom,
        loneliness, and isolation that seniors frequently experience are issues that our founders saw as urgently
        needing a solution.
      </p>
      <p className="servicediv_detail">
        Motivated by our love of helping others, we created a service that prioritizes mental and emotional wellness in
        addition to physical health. At i-Companion, we recognize that genuine treatment necessitates personal
        connection, respect, and compassion in addition to medical attention. Assuring that the elders we serve feel
        heard, respected, and supported is something our team takes great pleasure in developing deep relationships
        with.
      </p>
      <h2 className="servicedes">What We Do</h2>

      <p className="servicediv_detail">
        i-Companion offers a comprehensive range of in-home companion care services designed to assist seniors in their
        daily lives. Our services include:
      </p>
      <ul>
        <li className="service_data">
          <strong>companionship: </strong> Seniors' quality of life can be significantly enhanced by conversing with
          them, playing games with them, or just being a soothing presence. Our caregivers provide company that reduces
          feelings of isolation and improves mental health.
        </li>
        <li className="service_data">
          <strong>Errand Assistance: </strong> Our caregivers are available to help seniors manage their everyday
          responsibilities while making sure they maintain their connections to their communities, whether it's picking
          up medicines, food shopping, or running errands.
        </li>
        <li className="service_data">
          <strong>Meal Preparation: </strong> Seniors' health depends on a balanced diet. Our caregivers assist with
          meal preparation, planning, and making sure seniors eat wholesome, well-balanced meals.
        </li>
        <li className="service_data">
          <strong>Light Housekeeping: </strong>Our carers help seniors with light domestic duties, such as cleaning up
          and washing, so they can maintain a clean and organised living area without having to put too much physical
          pressure on themselves.
        </li>
        <li className="service_data">
          <strong>Errand Assistance: </strong> Our caregivers are available to help seniors manage their everyday
          responsibilities while making sure they maintain their connections to their communities, whether it's picking
          up medicines, food shopping, or running errands.
        </li>
        <li className="service_data">
          <strong>Transportation:</strong> To help elderly stay active and independent, we offer transportation to
          doctor's appointments, social events, and other vital places.
        </li>
      </ul>
      <h2 className="servicedes">Our Values</h2>

      <p className="servicediv_detail">At i-Companion, our values define who we are and guide everything we do:</p>
      <ul>
        <li className="service_data">
          <strong>Compassion:</strong> We consider elderly people to be part of our own family and treat them
          accordingly. Every customer is treated with kindness, tolerance, and compassion by our staff.
        </li>
        <li className="service_data">
          <strong>Integrity:</strong> We make sure that every family that entrusts us with their loved ones receives the
          best care possible by conducting business with honesty and openness.
        </li>
        <li className="service_data">
          <strong>Respect:</strong> All seniors should be treated with honour and decency. We pay attention to their
          requirements, respect their choices, and encourage their independence.
        </li>
        <li className="service_data">
          <strong>Excellence:</strong> We're dedicated to giving our clients outstanding service, enhancing our care
          plans on a regular basis, and remaining at the forefront of the elder care sector.
        </li>
      </ul>
      <h2 className="servicedes">Why Choose Us?</h2>

      <p className="servicediv_detail">
        Choosing i-Companion means entrusting your loved one’s care to a team that values their well-being above all
        else. Our caregivers are carefully selected, thoroughly vetted, and extensively trained to provide the highest
        level of care. We believe in building lasting relationships with the families we serve, ensuring that every
        senior feels supported and cherished.
      </p>
      <p className="servicediv_detail">
        At i-Companion, we are more than just caregivers—we are companions, advocates, and trusted partners. Our focus
        on compassionate, personalized care sets us apart, and we are proud to be a trusted resource for families
        seeking quality care for their loved ones.
      </p>
    </>
  );
};
export default About;
